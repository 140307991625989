import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { useIntl } from 'gatsby-plugin-intl'

export function FaqContent({ mdFiles }) {
    const intl = useIntl()
    const { locale } = intl

    console.log(mdFiles[0].node.frontmatter.title)
    console.log(locale)
    const faqMD = mdFiles.find(md => md.node.frontmatter.title === locale)?.node
        .html
    console.log(faqMD)
    return (
        <>
            <FaqContainer>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {faqMD}
                </ReactMarkdown>
            </FaqContainer>
        </>
    )
}

const FaqContainer = styled.div`
    margin: 160px auto;
    padding: 0 24px;
    width: 100%;
    max-width: 864px;
    @media (max-width: 900px) {
        margin: 40px auto;
    }
    ul,
    ol {
        color: #8f9fa9;
        margin-bottom: 40px;
    }

    h2 {
        text-align: start;
        font-size: 24px;
        line-height: 30px;
        font-weight: 900;
        margin-bottom: 40px;
    }

    h3 {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 15px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        color: #8f9fa9;
        margin-bottom: 40px;
    }

    span {
        display: block;
        color: #8f9fa9;
        margin-top: 5px;
        margin-bottom: 40px;
        font-size: 15px;
        line-height: 19px;
    }
    .gatsby-resp-image-wrapper {
        max-width: 250px !important;
        @media (max-width: 900px) {
            min-width: 200px !important;
        }
    }
    div {
        background-color: #eef3f5;
        width: 100%;
        min-height: 327px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        img {
            max-width: 300px;
        }
        @media (max-width: 900px) {
            min-height: 151px;
        }
    }
`
