import React from 'react'
import { useContactForm } from './contactForm.hooks'
import { useIntl } from 'gatsby-plugin-intl'
import {
    Wrapper,
    Title,
    Form,
    InputGroup,
    Label,
    Input,
    ErrorMsg,
    Textarea,
    SendBtn,
} from './contactForm.styled'

export function ContactForm() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const {
        name,
        surname,
        email,
        phone,
        question,
        handleSubmit,
        onChange,
        formValid,
        sendErr,
    } = useContactForm()

    return (
        <Wrapper>
            <Title>{t('common_form_title')}</Title>
            <Form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <InputGroup>
                    <Label error={name.isValid ? 0 : 1}>
                        {t('common_form_name')}
                    </Label>
                    <Input
                        value={name.value}
                        name="name"
                        placeholder={t('common_form_enter')}
                        onChange={onChange}
                    />
                    <ErrorMsg show={!name.isValid}>
                        {t('common_form_name_error_message')}
                    </ErrorMsg>
                </InputGroup>
                <InputGroup>
                    <Label error={surname.isValid ? 0 : 1}>
                        {t('common_form_surname')}
                    </Label>
                    <Input
                        value={surname.value}
                        name="surname"
                        placeholder={t('common_form_enter')}
                        onChange={onChange}
                    />
                    <ErrorMsg show={!surname.isValid}>
                        {t('common_form_surname_error_message')}
                    </ErrorMsg>
                </InputGroup>
                <InputGroup>
                    <Label error={email.isValid ? 0 : 1}>
                        {t('common_form_email')}
                    </Label>
                    <Input
                        value={email.value}
                        type="email"
                        name="email"
                        placeholder={t('common_form_enter')}
                        onChange={onChange}
                    />
                    <ErrorMsg show={!email.isValid}>
                        {t('common_form_email_error_message')}
                    </ErrorMsg>
                </InputGroup>
                <InputGroup>
                    <Label error={phone.isValid ? 0 : 1}>
                        {t('common_form_telephone')}
                    </Label>
                    <Input
                        value={phone.value}
                        type="tel"
                        name="phone"
                        placeholder={t('common_form_enter')}
                        onChange={onChange}
                    />
                    <ErrorMsg show={!phone.isValid}>
                        {t('common_form_telephone_error_message')}
                    </ErrorMsg>
                </InputGroup>
                <InputGroup isFull>
                    <Label error={question.isValid ? 0 : 1}>
                        {t('common_form_question')}
                    </Label>
                    <Textarea
                        value={question.value}
                        name="question"
                        placeholder={t('common_form_message_content')}
                        onChange={onChange}
                    />
                    <ErrorMsg show={!question.isValid}>
                        {t('common_form_question_error_message')}
                    </ErrorMsg>
                </InputGroup>
                <SendBtn disabled={!formValid} type="submit">
                    {t('common_form_send')}
                </SendBtn>
                <ErrorMsg show={sendErr ? 1 : 0} fade={true}>
                    {t('common_form_error_massage')}
                </ErrorMsg>
            </Form>
        </Wrapper>
    )
}
