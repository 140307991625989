import { useReducer, useState } from 'react'
import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { globalHistory } from '@reach/router'

function reducer(state, { field, value, isValid }) {
    return {
        ...state,
        [field]: { value: value, isValid: isValid },
    }
}

function validate(field, value) {
    switch (field) {
        case 'name':
        case 'surname':
            if (
                value.match(
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
                ) &&
                value.length > 2
            )
                return true
            return false
        case 'email':
            return value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                ? true
                : false
        case 'phone':
            const phoneNumberOnlyDigits = value.replace(/[^0-9]/g, '')
            return phoneNumberOnlyDigits.match(/[0-9]+/) &&
                phoneNumberOnlyDigits.length > 6
                ? true
                : false
        case 'question':
            return value.length > 2 ? true : false
        default:
            return false
    }
}

const initialState = {
    name: { value: '', isValid: true },
    surname: { value: '', isValid: true },
    email: { value: '', isValid: true },
    phone: { value: '', isValid: true },
    question: { value: '', isValid: true },
}

export const useContactForm = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [formValid, setFormValid] = useState(false)
    const [sendErr, setSendErr] = useState(false)
    const lang = globalHistory.location.pathname.slice(1, 3)

    const redirectThankYou = () => {
        navigate(`/${lang}/thank-you`)
    }
    const encode = data => {
        return Object.keys(data)
            .map(
                key =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key])
            )
            .join('&')
    }

    const checkIsFormValid = () => {
        Object.keys(state).every(
            item => state[item].isValid === true && state[item].value !== ''
        )
            ? setFormValid(true)
            : setFormValid(false)
    }

    const checkInputsError = () => {
        Object.keys(state).forEach(item => {
            let value = state[item].value
            let itemValid = validate(item, value)
            dispatch({ field: item, value, isValid: itemValid })
        })
    }

    const onChange = e => {
        const isValid = validate(e.target.name, e.target.value)
        dispatch({ field: e.target.name, value: e.target.value, isValid })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        checkInputsError()
        checkIsFormValid()
        const formBody = JSON.parse(JSON.stringify(state))
        Object.keys(formBody).forEach(key => {
            formBody[key] = formBody[key].value
        })

        if (formValid) {
            try {
                await fetch('/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: encode({ 'form-name': 'contact', ...formBody }),
                })

                redirectThankYou()
            } catch (error) {
                console.log(error)
                setSendErr(true)
                setTimeout(() => setSendErr(false), 5000)
            }
        } else {
            setSendErr(true)
            setTimeout(() => setSendErr(false), 5000)
        }
    }

    const { name, surname, email, phone, question } = state

    useEffect(() => checkIsFormValid())

    return {
        name,
        surname,
        email,
        phone,
        question,
        sendErr,
        onChange,
        handleSubmit,
        formValid,
    }
}
