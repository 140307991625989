import React from 'react'
import styled from 'styled-components'
import { DoctorsPhotosRow, Section, SectionTitle } from '../../components'
import ShapeApproved4 from '../../images/ShapeApproved4.svg'
import ShapeApproved5 from '../../images/ShapeApproved5.svg'
import { useIntl } from 'gatsby-plugin-intl'
import { StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'

const ProfileImageContainer = styled.div`
    width: 230px;
    margin: 50px auto;
    margin-bottom: 40px;
    @media (max-width: 900px) {
        width: 135px;
    }
`
export function TeamInsights() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <Section>
            <InformationRow>
                <InformationBold>
                    {t('team_insights_info_left_column')}
                </InformationBold>
                <InformationContent>
                    {t('team_insights_info_right_column')}
                </InformationContent>
            </InformationRow>
            <SectionTitle> {t('team_doctors_team_name')}</SectionTitle>
            <DoctorsPhotosRow />
            <SectionTitle>{t('team_developers_team_name')}</SectionTitle>
            <Row>
                <Figure>
                    <ShapeApproved4 />

                    <ProfileImageContainer>
                        <StaticImage
                            src="../../images/allbrightMarek.png"
                            alt={t('team_cto_name')}
                        />
                    </ProfileImageContainer>

                    <span>{t('team_cto_name')}</span>
                    <span>{parse(t('team_cto_description'))}</span>
                </Figure>
                <Figure>
                    <ShapeApproved5 />
                    <ProfileImageContainer>
                        <StaticImage
                            src="../../images/allbrightPiotr.png"
                            alt={t('team_cto_name')}
                        />
                    </ProfileImageContainer>
                    <span>{t('team_coo_name')}</span>
                    <span>{parse(t('team_coo_description'))}</span>
                </Figure>
            </Row>
        </Section>
    )
}

const InformationRow = styled.div`
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    max-width: 1455px;
    margin: 0 auto;
    padding: 0 24px 100px 24px;
    color: #2e2f45;
    @media (max-width: 900px) {
        padding-bottom: 40px;
        padding-top: 50px;
    }
`

const InformationBold = styled.h2`
    margin: 0 0 25px 0;
    text-align: left;
    min-width: 300px;
    max-width: 500px;
    font-size: 40px;
    line-height: 54px;
    @media (max-width: 1000px) {
        margin: 0 auto 25px auto;
        font-size: 28px;
        line-height: 40px;
        min-width: auto;
    }
`

const InformationContent = styled.p`
    margin: 0 0 25px 0;
    font-size: 20px;
    min-width: 300px;
    line-height: 34px;
    max-width: 500px;
    color: #8f9fa9;
    @media (max-width: 1000px) {
        margin: 0 auto;
        font-size: 20px;
        min-width: auto;
        line-height: 34px;
        max-width: 100%;
    }
`

const Figure = styled.div`
    overflow: hidden;
    width: 342px;
    height: 481px;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
        position: absolute;
        left: 0;
        top: -65px;
        width: 342px;
        height: 342px;
    }
    a {
        font-size: 16px;
        line-height: 20px;
        color: #8f9fa9;
    }
    span:nth-of-type(1) {
        font-size: 17px;
        line-height: 21px;
        font-weight: 800;
        color: #2e2f45;
        margin-bottom: 16px;
    }
    span:nth-of-type(2) {
        font-size: 16px;
        line-height: 20px;
        color: #8f9fa9;
    }
    @media (max-width: 900px) {
        height: 380px;
        svg {
            top: -88px;
            width: 192px;
            left: calc(50% - 96px);
        }
        span:nth-of-type(1) {
            margin-bottom: 6px;
        }
    }
`

const Row = styled.div`
    margin: 160px auto;
    max-width: 1220px;
    display: Grid;
    grid-template-columns: 1fr 1fr;
    margin: 40px auto;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`
