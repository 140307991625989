import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'

export function ThankYou() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    return (
        <Wrapper>
            <ThanksMassage>
                <span>{t('common_form_thank_you_message')}</span>
            </ThanksMassage>
        </Wrapper>
    )
}

const ThanksMassage = styled.section`
    height: 100%;
    margin: 260px auto;
    padding: 0 24px;
    width: 100%;
    max-width: 864px;
    text-align: center;
    font-weight: 800;
    font-size: 50px;
    line-height: 63px;
    @media (max-width: 900px) {
        margin: 140px auto;
        font-size: 36px;
        line-height: 44px;
    }
`

const Wrapper = styled.section`
    background-color: #f3efff;
    min-height: calc(100vh - 448px);
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin: 0;
    padding: 0 24px;
    align-items: stretch;
`
