import React from 'react'
import styled from 'styled-components'
import introShape from '../../images/introShapeWhite.svg'
import PropTypes from 'prop-types'
import { DecorationStripe } from '../../components'
export function IntroSubpage(props) {
    const { bg, decorationsColor, description } = props
    return (
        <Wrapper bg={bg}>
            <Content>
                <Description>{description}</Description>

                <DecoratorBox>
                    {decorationsColor.map((color, index) => {
                        return <DecorationStripe key={index} color={color} />
                    })}
                </DecoratorBox>
            </Content>
            <IntroShape />
        </Wrapper>
    )
}

IntroSubpage.propTypes = {
    bg: PropTypes.string,
    decorationsColor: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    description: PropTypes.string,
}

IntroSubpage.defaultProps = {
    bg: '#E8FBF8',
    decorationsColor: ['#3D278A', '#04968B', '#51E2C9'],
    title: 'Czym jest aplikacja:',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
  minim.`,
}

const Content = styled.div`
    padding-top: 240px;
    max-width: 1220px;
    margin: 0 auto;
    z-index: 10;
`

const DecoratorBox = styled.div`
    margin-top: 60px;
    svg {
        margin-left: 20px;
    }
    svg:first-child {
        margin: 0;
    }
`

const Description = styled.span`
    color: #2e2f45;
    font-weight: 900;
    font-size: 40px;
    line-height: 54px;
    z-index: 6;
    position: relative;
    max-width: 1000px;
    display: block;
    @media (max-width: 900px) {
        font-size: 28px;
        line-height: 40px;
    }
`

const IntroShape = styled(introShape)`
    position: absolute;
    right: -8%;
    top: -49%;
    height: 826px;
    width: 754px;
    @media (max-width: 900px) {
        top: -45%;
        width: 80vw;
    }
`

const Wrapper = styled.section`
    padding: 0 24px;
    background: ${props => props.bg};
    width: 100%;
    height: 650px;
    position: relative;
    @media (max-width: 900px) {
        height: auto;
        padding-bottom: 90px;
    }
`
