import React, { useEffect } from 'react'
import { DoctorsPhotosRow } from '../../components'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Section } from '../../components/section'
import { SectionTitle } from '../../components/sectionTitle'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

export function DoctorsRow() {
    const [ref, inView] = useInView()
    const controls = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])

    return (
        <Section>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 30 }}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                    ease: 'easeOut',
                }}
            >
                <SectionTitle>
                    <FormattedMessage id="home_section_with_doctors_photo_row_title" />
                </SectionTitle>
            </motion.div>

            <DoctorsPhotosRow />
        </Section>
    )
}
