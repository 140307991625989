import React from 'react'
import styled from 'styled-components'
import BoringCompany from '../../images/boringCompany.svg'
import Polpharma from '../../images/polpharma.svg'
import Microsoft from '../../images/microsoft.svg'
import { Section } from '../../components'

const Row = styled.div`
    background: #edf2f4;
    height: 280px;
    display: flex;
`

const Container = styled.div`
    padding: 50px 15px;
    max-width: 1220px;
    width: 100%;
    padding: 0 15px;
    margin: auto;
    display: flex;
    align-self: center;
    justify-content: space-around;
    flex-wrap: wrap;
    svg {
        opacity: 0.6;
    }
    @media (max-width: 900px) {
        padding: 50px 24px;
        svg {
            margin: 4vmin 1vmin;
            width: 115px;
        }
    }
`

export function Partners() {
    return (
        <Section>
            <Row>
                <Container>
                    <Polpharma />
                    <Microsoft />
                    <BoringCompany />
                    <BoringCompany />
                </Container>
            </Row>
        </Section>
    )
}
