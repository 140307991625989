import React from 'react'
import ReactMarkdown from 'react-markdown'
import termsEn from '../../markdown-pages/en/terms-and-conditions-mobile.md'
import termsPl from '../../markdown-pages/pl/terms-and-conditions-mobile.md'

/**
 * @param {{ locale: 'pl' | 'en' | 'de' }} param
 */
export const TermsAndConditionsMobile = ({ locale }) => {
    switch (locale) {
        case 'en':
            return <ReactMarkdown>{termsEn}</ReactMarkdown>
        default:
            return <ReactMarkdown>{termsPl}</ReactMarkdown>
    }
}
