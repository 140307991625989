import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import { useAnimatedFeatures } from './useAnimatedFeatures'
import { motion } from 'framer-motion'

export function Features() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const { locale } = intl
    const {
        ref,
        controls,
        ref2,
        controls2,
        ref3,
        controls3,
        ref4,
        controls4,
        ref5,
        controls5,
        ref6,
        controls6,
        ref7,
        controls7,
        ref8,
        controls8,
    } = useAnimatedFeatures()

    const data = useStaticQuery(graphql`
        query {
            featurePl1: file(relativePath: { eq: "feature1pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn1: file(relativePath: { eq: "feature1en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe1: file(relativePath: { eq: "feature1pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl2: file(relativePath: { eq: "feature2pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn2: file(relativePath: { eq: "feature2en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe2: file(relativePath: { eq: "feature2pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl3: file(relativePath: { eq: "feature3pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn3: file(relativePath: { eq: "feature3en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe3: file(relativePath: { eq: "feature3pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl4: file(relativePath: { eq: "feature4pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn4: file(relativePath: { eq: "feature4en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe4: file(relativePath: { eq: "feature4pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl5: file(relativePath: { eq: "feature5pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn5: file(relativePath: { eq: "feature5en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe5: file(relativePath: { eq: "feature5pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl6: file(relativePath: { eq: "feature6pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn6: file(relativePath: { eq: "feature6en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe6: file(relativePath: { eq: "feature6pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl7: file(relativePath: { eq: "feature7pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn7: file(relativePath: { eq: "feature7en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe7: file(relativePath: { eq: "feature7pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featurePl8: file(relativePath: { eq: "feature8pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureEn8: file(relativePath: { eq: "feature8en.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            featureDe8: file(relativePath: { eq: "feature8pl.png" }) {
                childImageSharp {
                    fixed(width: 330, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
        }
    `)
    const feature1 =
        locale === 'en'
            ? data.featureEn1
            : locale === 'de'
            ? data.featureDe1
            : data.featurePl1
    const feature2 =
        locale === 'en'
            ? data.featureEn2
            : locale === 'de'
            ? data.featureDe2
            : data.featurePl2
    const feature3 =
        locale === 'en'
            ? data.featureEn3
            : locale === 'de'
            ? data.featureDe3
            : data.featurePl3
    const feature4 =
        locale === 'en'
            ? data.featureEn4
            : locale === 'de'
            ? data.featureDe4
            : data.featurePl4
    const feature5 =
        locale === 'en'
            ? data.featureEn5
            : locale === 'de'
            ? data.featureDe5
            : data.featurePl5
    const feature6 =
        locale === 'en'
            ? data.featureEn6
            : locale === 'de'
            ? data.featureDe6
            : data.featurePl6
    const feature7 =
        locale === 'en'
            ? data.featureEn7
            : locale === 'de'
            ? data.featureDe7
            : data.featurePl7
    const feature8 =
        locale === 'en'
            ? data.featureEn8
            : locale === 'de'
            ? data.featureDe8
            : data.featurePl8

    return (
        <Wrapper>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 30 }}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                    ease: 'easeOut',
                }}
            >
                <Title>{t('for_doctor_section_features_header_1')}</Title>
            </motion.div>
            <Grid style={{ marginBottom: '200px' }}>
                <TextLeft>
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.5,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_1')}</h3>
                        <p>{t('for_doctor_section_features_message_1')}</p>
                    </motion.div>
                </TextLeft>
                <ImageRight isPink={true}>
                    <PhoneAdjust
                        fadeIn={false}
                        fixed={feature1.childImageSharp.fixed}
                    />
                </ImageRight>

                <TextRight>
                    <motion.div
                        ref={ref2}
                        initial={{ opacity: 0, x: 50 }}
                        animate={controls2}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_2')}</h3>
                        <p>{t('for_doctor_section_features_message_2')}</p>
                    </motion.div>
                </TextRight>
                <ImageLeft>
                    <Phone
                        fadeIn={false}
                        fixed={feature2.childImageSharp.fixed}
                    />
                </ImageLeft>
                <TextLeft2>
                    <motion.div
                        ref={ref3}
                        initial={{ opacity: 0, x: -50 }}
                        animate={controls3}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_3')}</h3>
                        <p>{t('for_doctor_section_features_message_3')}</p>
                    </motion.div>
                </TextLeft2>
                <ImageRight2 isPink={true}>
                    <Phone
                        fadeIn={false}
                        fixed={feature3.childImageSharp.fixed}
                    />
                </ImageRight2>
                <TextRight2>
                    <motion.div
                        ref={ref4}
                        initial={{ opacity: 0, x: 50 }}
                        animate={controls4}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_4')}</h3>
                        <p>{t('for_doctor_section_features_message_4')}</p>
                    </motion.div>
                </TextRight2>
                <ImageLeft2>
                    <Phone
                        fadeIn={false}
                        fixed={feature4.childImageSharp.fixed}
                    />
                </ImageLeft2>
            </Grid>
            <Grid>
                <TextLeft>
                    <motion.div
                        ref={ref5}
                        initial={{ opacity: 0, x: -50 }}
                        animate={controls5}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_5')}</h3>
                        <p>{t('for_doctor_section_features_message_5')}</p>
                    </motion.div>
                </TextLeft>
                <ImageRight>
                    <Phone
                        fadeIn={false}
                        fixed={feature5.childImageSharp.fixed}
                    />
                </ImageRight>
                <TextRight>
                    <motion.div
                        ref={ref6}
                        initial={{ opacity: 0, x: 50 }}
                        animate={controls6}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_6')}</h3>
                        <p>{t('for_doctor_section_features_message_6')}</p>
                    </motion.div>
                </TextRight>
                <ImageLeft>
                    <Phone
                        fadeIn={false}
                        fixed={feature6.childImageSharp.fixed}
                    />
                </ImageLeft>
                <TextLeft2>
                    <motion.div
                        ref={ref7}
                        initial={{ opacity: 0, x: -50 }}
                        animate={controls7}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_7')}</h3>
                        <p>{t('for_doctor_section_features_message_7')}</p>
                    </motion.div>
                </TextLeft2>
                <ImageRight2>
                    <Phone
                        fadeIn={false}
                        fixed={feature7.childImageSharp.fixed}
                    />
                </ImageRight2>
                <TextRight2>
                    <motion.div
                        ref={ref8}
                        initial={{ opacity: 0, x: 50 }}
                        animate={controls8}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        <h3>{t('for_doctor_section_features_subheader_8')}</h3>
                        <p>{t('for_doctor_section_features_message_8')}</p>
                    </motion.div>
                </TextRight2>
                <ImageLeft2>
                    <Phone
                        fadeIn={false}
                        fixed={feature8.childImageSharp.fixed}
                    />
                </ImageLeft2>
            </Grid>
        </Wrapper>
    )
}

const Phone = styled(Img)`
    transform: translateX(40px);
    margin: auto auto 0 auto;
    @media (max-width: 900px) {
        transform: scale(0.6) translate(40px, -47px);
    }
`
const PhoneAdjust = styled(Phone)`
    @media (max-width: 900px) {
        transform: scale(0.6) translate(40px, -44px);
    }
`

const Wrapper = styled.section`
    width: 100%;
    padding: 0 24px 0 24px;
    @media (max-width: 900px) {
        padding: 0 24px 0px 24px;
    }
`

const Title = styled.h2`
    margin: 100px auto;
    font-size: 40px;
    line-height: 54px;
    @media (max-width: 900px) {
        font-size: 28px;
        line-height: 40px;
        margin: 60px auto 60px;
    }
`

const Grid = styled.div`
    display: grid;
    margin: 0 auto 100px auto;
    max-width: 1220px;
    grid-template-columns: repeat(1fr, 1fr);
    row-gap: 200px;
    grid-template-areas:
        'TextLeft   ImageRight'
        'ImageLeft  TextRight'
        'TextLeft2 ImageRight2'
        'ImageLeft2  TextRight2';
    @media (max-width: 900px) {
        row-gap: 80px;
        grid-template-columns: 1fr;
        grid-template-areas:
            'ImageRight'
            'TextLeft'
            'ImageLeft'
            'TextRight'
            'ImageRight2'
            'TextLeft2'
            'ImageLeft2'
            'TextRight2';
        margin-bottom: 20px;
    }
`

const TextLeft = styled.div`
    margin-right: auto;
    padding-right: 14px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    max-width: 510px;
    grid-area: TextLeft;
    h3 {
        font-size: 40px;
        line-height: 54px;
    }
    p {
        color: #8f9fa9;
        line-height: 34px;
        font-size: 20px;
        margin-bottom: 50px;
    }
    @media (max-width: 900px) {
        h3 {
            font-size: 28px;
        }
        max-width: 100%;
        padding-right: 0;
        margin-right: 0;
        max-width: calc(100vw - 48px);
    }
`

const TextLeft2 = styled(TextLeft)`
    grid-area: TextLeft2;
`

const ImageRight = styled.div`
    width: 100%;
    max-width: 610px;
    margin-left: auto;
    display: flex;
    grid-area: ImageRight;
    border-radius: 12px;
    background: ${props => (props.isPink ? '#f3efff' : '#E8FBF8')};
    height: 460px;
    @media (max-width: 900px) {
        min-width: 100vw;
        transform: translateX(-24px);
        border-radius: 0;
        height: 284px;
    }
`
const ImageRight2 = styled(ImageRight)`
    grid-area: ImageRight2;
`

const TextRight = styled.div`
    grid-area: TextRight;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 510px;
    width: 100%;
    padding-left: 14px;
    margin-left: auto;
    h3 {
        font-size: 40px;
        line-height: 54px;
    }
    p {
        color: #8f9fa9;
        line-height: 34px;
        font-size: 20px;
        margin-bottom: 50px;
    }
    @media (max-width: 900px) {
        h3 {
            font-size: 28px;
        }
        max-width: 100%;
        padding-left: 0;
        margin-left: 0;
        max-width: calc(100vw - 48px);
    }
`

const TextRight2 = styled(TextRight)`
    grid-area: TextRight2;
`

const ImageLeft = styled.div`
    width: 100%;
    margin-right: auto;
    display: flex;
    grid-area: ImageLeft;
    border-radius: 12px;
    background: #eef3f5;
    max-width: 610px;
    width: 100%;
    height: 460px;
    @media (max-width: 900px) {
        min-width: 100vw;
        transform: translateX(-24px);
        border-radius: 0;
        height: 284px;
    }
`
const ImageLeft2 = styled(ImageLeft)`
    grid-area: ImageLeft2;
`
