import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useIntl } from 'gatsby-plugin-intl'
import { colors } from '../../utils/const'
import { DecorationStripe } from '../../components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

export function GreyBackgroundInformation(props) {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const decorationsColor = ['#3D278A', '#04968B', '#51E2C9']
    const [ref, inView] = useInView()
    const controls = useAnimation()

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])
    return (
        <Wrapper>
            <Row>
                <ColumnTitle>
                    <motion.h2
                        ref={ref}
                        initial={{ opacity: 0, x: -30 }}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        }}
                    >
                        {t('for_doctor_grey_background_information_header')}
                    </motion.h2>
                </ColumnTitle>
                <ColumnDetails>
                    <div>
                        <motion.span
                            ref={ref}
                            initial={{ opacity: 0, x: 0 }}
                            animate={controls}
                            transition={{
                                duration: 0.3,
                                delay: 0.3,
                                ease: 'easeOut',
                            }}
                        >
                            {t(
                                'for_doctor_grey_background_information_message'
                            )}
                        </motion.span>
                        <DecoationWrap>
                            {decorationsColor.map((color, index) => (
                                <DecorationStripe
                                    color={color}
                                    key={`decorator-gry-section=${index}`}
                                />
                            ))}
                        </DecoationWrap>
                    </div>
                </ColumnDetails>
            </Row>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    padding: 120px 24px 100px 24px;
    margin: 40px auto;
    width: 100%;
    background: ${colors.lightGrey2};
    height: 479px;
    @media (max-width: 900px) {
        height: auto;
        padding: 80px 24px 80px 24px;
    }
`

const Row = styled.div`
    display: Grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`

const ColumnTitle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    h2 {
        color: ${colors.primaryDark};
        font-weight: 800;
        font-size: 40px;
    }
    @media (max-width: 900px) {
        justify-content: start;
        h2 {
            font-size: 28px;
            line-height: 40px;
        }
    }
`
const ColumnDetails = styled.div`
    margin-left: 29px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    span {
        font-size: 20px;
        line-height: 34px;
        color: ${colors.mediumGrey};
    }
    > div {
        max-width: 510px;
    }
    @media (max-width: 900px) {
        margin-left: 0;
    }
`

const DecoationWrap = styled.div`
    margin-top: 40px;
    svg {
        margin-left: 20px;
    }
    svg:first-child {
        margin: 0;
    }
`
