import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

export const useAnimatedFeatures = () => {
    const [ref, inView] = useInView()
    const [ref2, inView2] = useInView()
    const [ref3, inView3] = useInView()
    const [ref4, inView4] = useInView()
    const [ref5, inView5] = useInView()
    const [ref6, inView6] = useInView()
    const [ref7, inView7] = useInView()
    const [ref8, inView8] = useInView()
    const controls = useAnimation()
    const controls2 = useAnimation()
    const controls3 = useAnimation()
    const controls4 = useAnimation()
    const controls5 = useAnimation()
    const controls6 = useAnimation()
    const controls7 = useAnimation()
    const controls8 = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView2) {
            controls2.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView3) {
            controls3.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView4) {
            controls4.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView5) {
            controls5.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView6) {
            controls6.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView7) {
            controls7.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView8) {
            controls8.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [
        controls,
        inView,
        controls2,
        inView2,
        controls3,
        inView3,
        controls4,
        inView4,
        controls5,
        inView5,
        controls6,
        inView6,
        controls7,
        inView7,
        controls8,
        inView8,
    ])

    return {
        ref,
        controls,
        ref2,
        controls2,
        ref3,
        controls3,
        ref4,
        controls4,
        ref5,
        controls5,
        ref6,
        controls6,
        ref7,
        controls7,
        ref8,
        controls8,
    }
}
