import React from 'react'
import ReactMarkdown from 'react-markdown'
import privacyEn from '../../markdown-pages/en/privacy-policy.md'
import privacyPl from '../../markdown-pages/pl/privacy-policy.md'
import privacyDe from '../../markdown-pages/de/privacy-policy.md'

export const PrivacyPolicy = ({ locale }) => {
    switch (locale) {
        case 'en':
            return <ReactMarkdown>{privacyEn}</ReactMarkdown>
        case 'de':
            return <ReactMarkdown>{privacyDe}</ReactMarkdown>
        default:
            return <ReactMarkdown>{privacyPl}</ReactMarkdown>
    }
}
