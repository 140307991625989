import React, { useEffect } from 'react'
import styled from 'styled-components'
import IconSegment from '../../components/iconSegment'
import BellIcon from '../../images/bell.svg'
import FavoriteIcon from '../../images/favorite.svg'
import MenuBookIcon from '../../images/menu_book.svg'
import DoneOutlineIcon from '../../images/done_outline.svg'
import PoolIcon from '../../images/pool.svg'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import CombinedShape from '../../images/CombinedShape.svg'
import { useIntl } from 'gatsby-plugin-intl'
import { Section } from '../../components/section'
import { SectionTitle } from '../../components/sectionTitle'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

export function HowItWorks() {
    const intl = useIntl()
    const { locale } = intl
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const [ref, inView] = useInView()
    const controls = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])

    const data = useStaticQuery(graphql`
        query {
            PhoneEn: file(relativePath: { eq: "phone_pressure_en.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            PhoneDe: file(relativePath: { eq: "phone_pressure_de.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350, quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            PhonePl: file(relativePath: { eq: "phone_pressure_pl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    const dataPhone =
        locale === 'en'
            ? data.PhoneEn
            : locale === 'de'
            ? data.PhoneDe
            : data.PhonePl

    const Segments = [
        {
            text: t('home_section_colorful_segments_segment_1'),
            icon: BellIcon,
            color: '#F3EFFF',
        },
        {
            text: t('home_section_colorful_segments_segment_2'),
            icon: FavoriteIcon,
            color: '#FFE4EA',
        },
        {
            text: t('home_section_colorful_segments_segment_3'),
            icon: MenuBookIcon,
            color: '#FFF7D1',
        },
        {
            text: t('home_section_colorful_segments_segment_4'),
            icon: DoneOutlineIcon,
            color: '#E2F3F1',
        },
        {
            text: t('home_section_colorful_segments_segment_5'),
            icon: PoolIcon,
            color: '#EEF2F5',
        },
    ]

    return (
        <Section>
            <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={controls}
                transition={{ duration: 0.3, delay: 0, ease: 'easeOut' }}
            >
                <SectionTitle>
                    {t('home_section_colorful_segments_title')}
                </SectionTitle>
            </motion.div>

            <Row>
                <ColumnPhone>
                    <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0,
                            ease: 'easeOut',
                        }}
                    >
                        <Phone
                            fadeIn={false}
                            fluid={dataPhone.childImageSharp.fluid}
                        />
                    </motion.div>

                    <Shape />
                </ColumnPhone>
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: 30 }}
                    animate={controls}
                    transition={{
                        duration: 0.3,
                        delay: 0.3,
                        ease: 'easeOut',
                    }}
                >
                    <Description>
                        <h3>{t('home_section_colorful_segments_subtitle')}</h3>
                        <p>{t('home_section_colorful_segments_message')}</p>
                    </Description>
                </motion.div>
                <SegmentsBox>
                    {Segments.map((segment, index) => {
                        const { color, icon, text } = segment
                        return (
                            <motion.div
                                initial={{ opacity: 0, x: 30 }}
                                animate={controls}
                                transition={{
                                    duration: 0.3,
                                    delay: 0.6 + index / 10,
                                    ease: 'easeOut',
                                }}
                                key={index}
                            >
                                <IconSegment
                                    text={text}
                                    icon={icon}
                                    color={color}
                                />
                            </motion.div>
                        )
                    })}
                </SegmentsBox>
            </Row>
        </Section>
    )
}

const Row = styled.div`
    margin: 100px auto;
    display: Grid;
    grid-template-columns: 6fr 5fr;
    grid-template-areas:
        'ColumnPhone   Description'
        'ColumnPhone  SegmentsBox';
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        margin: 60px 0;
        grid-template-areas:
            'Description'
            'ColumnPhone'
            'SegmentsBox';
    }
`

const ColumnPhone = styled.div`
    min-width: 510px;
    width: 100%;
    position: relative;
    grid-area: ColumnPhone;
    @media (max-width: 900px) {
        min-width: auto;
    }
`
const Description = styled.div`
    width: 100%;
    position: relative;
    grid-area: Description;
    h3 {
        font-weight: 800;
        font-size: 40px;
        line-height: 54px;
        max-width: 510px;
        margin-bottom: 30px;
    }
    p {
        font-size: 20px;
        line-height: 34px;
        color: #8f9fa9;
        max-width: 510px;
        margin-bottom: 60px;
    }
    @media (max-width: 900px) {
        padding: 60px 24px 0 24px;
        h2 {
            font-size: 36px;
            line-height: 45px;
        }
        h3 {
            font-size: 28px;
            line-height: 40px;
            max-width: 100%;
        }
        p {
            max-width: 100%;
        }
    }
`

const SegmentsBox = styled.div`
    width: 100%;
    position: relative;
    grid-area: SegmentsBox;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
        margin-top: 100px;
    }
`

const Phone = styled(Img)`
    width: 300px;
    margin-top: 170px;
    margin-left: auto;
    margin-right: auto;
    z-index: 4;
    @media (max-width: 900px) {
        margin: 0 auto;
        width: 220px;
    }
`

const Shape = styled(CombinedShape)`
    z-index: -1;
    position: absolute;
    top: 200px;
    left: calc(50% - 255px);
    width: 510px;
    height: 510px;
    @media (max-width: 900px) {
        top: 70px;
        left: calc(50% - 130px);
        width: 260px;
        height: 260px;
    }
`
