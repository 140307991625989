import styled from 'styled-components'
import { Button } from '../../components/button'

export const ErrorMsg = styled.span`
    margin: 10px 0;
    color: ${props => (props.show ? 'red' : 'transparent')};
    ${props => props.fade && `transition: 0.4s ease-in-out;`}
`

export const SendBtn = styled(Button)`
    margin-top: 40px;
    width: 200px;
    @media (max-width: 900px) {
        margin-bottom: 40px;
    }
`

export const Label = styled.label`
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
    transition: 0.4s ease-in-out;
    color: ${props => (props.error ? 'red' : '#2E2F45')};
`

export const Form = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto 60px auto;
    max-width: 696px;
    > div {
        display: flex;
        flex-direction: column;
    }
`

export const Input = styled.input`
    height: 56px;
    padding: 18px;
    border-radius: 10px;
    border: none;
    font-weight: 900;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #8f9fa9;
    }
`

export const InputGroup = styled.div`
    margin-top: 20px;
    max-width: ${props => (props.isFull ? '100%' : '328px')};
    width: ${props => (props.isFull ? '100%' : '50%')};
    @media (max-width: 900px) {
        max-width: 100%;
        width: 100%;
    }
`

export const Textarea = styled.textarea`
    height: 150px;
    padding: 18px;
    border-radius: 10px;
    border: none;
    font-weight: 900;
    resize: none;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #8f9fa9;
    }
`

export const Wrapper = styled.section`
    background-color: #f3efff;
    min-height: calc(100vh - 448px);
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin: 0;
    padding: 0 24px;
    align-items: stretch;
`

export const Title = styled.h2`
    margin: 160px auto 40px auto;
    font-size: 50px;
    line-height: 63px;
    max-width: 550px;
    @media (max-width: 900px) {
        margin: 100px auto 60px auto;
        font-size: 36px;
        line-height: 44px;
    }
`
