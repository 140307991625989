import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import DoctorDots from '../../images/doctorDots.svg'
import { useIntl } from 'gatsby-plugin-intl'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

export function ForDoctorSectionWithBullutes() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const { locale } = intl
    const [ref, inView] = useInView()
    const [ref2, inView2] = useInView()
    const controls = useAnimation()
    const controls2 = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
        if (inView2) {
            controls2.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView, controls2, inView2])
    const data = useStaticQuery(graphql`
        query {
            woman: file(relativePath: { eq: "womanBig.png" }) {
                childImageSharp {
                    fluid(maxWidth: 660, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            pulsometr: file(relativePath: { eq: "pulsometrBig.png" }) {
                childImageSharp {
                    fluid(maxWidth: 660, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            PhoneEn: file(relativePath: { eq: "phone_pressure_en.png" }) {
                childImageSharp {
                    fixed(width: 250, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            PhoneDe: file(relativePath: { eq: "phone_pressure_de.png" }) {
                childImageSharp {
                    fixed(width: 250, grayscale: true, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
            PhonePl: file(relativePath: { eq: "phone_pressure_pl.png" }) {
                childImageSharp {
                    fixed(width: 250, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                }
            }
        }
    `)

    const dataPhone =
        locale === 'en'
            ? data.PhoneEn
            : locale === 'de'
            ? data.PhoneDe
            : data.PhonePl

    return (
        <Wrapper>
            <ImageBox>
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: 50 }}
                    animate={controls}
                    transition={{ duration: 0.3, delay: 0.3, ease: 'easeOut' }}
                >
                    <PulsometrImage
                        durationFadeIn={0}
                        fluid={data.pulsometr.childImageSharp.fluid}
                        loading="eager"
                    />
                </motion.div>

                <WomanImageBox>
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.6,
                            ease: 'easeOut',
                        }}
                    >
                        <WomanImage
                            fadeIn={false}
                            fluid={data.woman.childImageSharp.fluid}
                        />
                    </motion.div>
                    <DotsImage />
                </WomanImageBox>
            </ImageBox>
            <Grid>
                <motion.div
                    ref={ref2}
                    initial={{ opacity: 0, x: -30 }}
                    animate={controls2}
                    transition={{ duration: 0.3, delay: 0.3, ease: 'easeOut' }}
                >
                    <Title>
                        {t('for_doctor_section_with_bullet_points_title')}
                    </Title>
                </motion.div>

                <ColumnPhone>
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={controls2}
                        transition={{
                            duration: 0.5,
                            delay: 0.5,
                            ease: 'easeOut',
                        }}
                    >
                        <Img
                            fadeIn={false}
                            fixed={dataPhone.childImageSharp.fixed}
                        />
                    </motion.div>

                    <Circle />
                </ColumnPhone>
                <List>
                    <ul>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={controls2}
                            transition={{
                                duration: 0.5,
                                delay: 0.3,
                                ease: 'easeOut',
                            }}
                        >
                            <p>
                                {t(
                                    'for_doctor_section_with_bullet_points_message_1'
                                )}
                            </p>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={controls2}
                            transition={{
                                duration: 0.5,
                                delay: 0.4,
                                ease: 'easeOut',
                            }}
                        >
                            <p>
                                {t(
                                    'for_doctor_section_with_bullet_points_message_2'
                                )}
                            </p>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={controls2}
                            transition={{
                                duration: 0.5,
                                delay: 0.5,
                                ease: 'easeOut',
                            }}
                        >
                            <p>
                                {t(
                                    'for_doctor_section_with_bullet_points_message_3'
                                )}
                            </p>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={controls2}
                            transition={{
                                duration: 0.5,
                                delay: 0.6,
                                ease: 'easeOut',
                            }}
                        >
                            <p>
                                {t(
                                    'for_doctor_section_with_bullet_points_message_4'
                                )}
                            </p>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: 50 }}
                            animate={controls2}
                            transition={{
                                duration: 0.5,
                                delay: 0.7,
                                ease: 'easeOut',
                            }}
                        >
                            <p>
                                {t(
                                    'for_doctor_section_with_bullet_points_message_5'
                                )}
                            </p>
                        </motion.li>
                    </ul>
                </List>
            </Grid>
        </Wrapper>
    )
}

const List = styled.div`
    width: 100%;
    grid-area: List;
    ul {
        margin-left: 29px;
        li {
            position: relative;
            margin-bottom: 55px;
            list-style-type: none;
            h3 {
                margin-bottom: 30px;
            }
            p {
                color: #8f9fa9;
                line-height: 34px;
                font-size: 20px;
                max-width: 510px;
                @media (max-width: 1330px) {
                    max-width: 100%;
                }
                @media (max-width: 900px) {
                    transform: translateX(-29px);
                    min-width: calc(100vw - 48px);
                    text-indent: 20px;
                }
            }
            &:before {
                position: absolute;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #fa104d;
                left: -29px;
                top: 13px;
            }
            &:nth-of-type(2):before {
                background: #3d278a;
            }
            &:nth-of-type(3):before {
                background: #51e2c9;
            }
            &:nth-of-type(4):before {
                background: #04968b;
            }
            &:nth-of-type(5):before {
                background: #3d278a;
            }
        }
    }
`

const Title = styled.h2`
    grid-area: Title;
    font-size: 40px;
    line-height: 54px;
    max-width: 510px;
    text-align: start;
    margin-bottom: 0px;
    margin: 0 auto;
    @media (max-width: 900px) {
        font-size: 28px;
        line-height: 40px;
        max-width: 90vw;
    }
`

const ColumnPhone = styled.div`
    grid-area: ColumnPhone;
    display: flex;
    justify-content: center;
    position: relative;
    @media (max-width: 900px) {
        padding-right: 40px;
        transform: scale(0.7);
    }
`

const Circle = styled.div`
    top: 20px;
    position: absolute;
    width: 440px;
    height: 440px;
    border-radius: 50%;
    background: #e8fbf8;
    z-index: -1;
`

const Grid = styled.div`
    margin-top: 140px;
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 6fr 5fr;
    grid-template-areas:
        'Title   List'
        'ColumnPhone  List';
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            'Title'
            'ColumnPhone'
            'List';
    }
`

const WomanImageBox = styled.div`
    position: relative;
`

const WomanImage = styled(Img)`
    position: absolute;
    width: 50%;
    left: 0vw;
    top: -340px;
    z-index: 1;
    @media (max-width: 900px) {
        width: 90%;
        left: -24px;
        z-index: 6;
        top: -150px;
    }
`

const PulsometrImage = styled(Img)`
    position: absolute;
    top: -100px;
    left: 560px;
    width: 50%;
    z-index: 5;
    @media (max-width: 900px) {
        width: 90%;
        left: auto;
        right: -124px;
    }
`

const DotsImage = styled(DoctorDots)`
    position: absolute;
    top: calc(25% - 95px);
    left: calc(50% - 85px);
    width: 218px;
    height: 218px;
    z-index: 0;
    @media (max-width: 1000px) {
        top: -150px;
    }
    @media (max-width: 900px) {
        top: 100px;
        width: 40vw;
        height: 40vw;
        left: 30vw;
    }
    @media (max-width: 500px) {
        top: 0px;
    }
`

const ImageBox = styled.div`
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1220px;
`

const Wrapper = styled.section`
    width: 100%;
    padding: 0 24px;
    @media (max-width: 900px) {
        padding-top: 60px;
    }
`
