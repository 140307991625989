import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import DiagnosticGreenDecorator from '../../images/diagnosticDecoratorGreen.svg'
import { useIntl } from 'gatsby-plugin-intl'
import { SectionTitle } from '../../components/sectionTitle'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

const ImagesAndDecorations = data => {
    return (
        <ImagesAndDecorationsWrapper>
            <div>
                <GreenDecorator />
                <PulsometrImage
                    fadeIn={false}
                    fluid={data.pulsometr.childImageSharp.fluid}
                />
                <WomanImageBox>
                    <WomanImage
                        fadeIn={false}
                        fluid={data.woman.childImageSharp.fluid}
                    />
                    <PurpleCircle />
                    <RedSquare>
                        <Heart3d
                            fadeIn={false}
                            fluid={data.heart3d.childImageSharp.fluid}
                        />
                    </RedSquare>
                </WomanImageBox>
            </div>
        </ImagesAndDecorationsWrapper>
    )
}

export function HomeSectionWithBullets() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const [ref, inView] = useInView()
    const controls = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])

    const data = useStaticQuery(graphql`
        query {
            woman: file(relativePath: { eq: "woman.png" }) {
                childImageSharp {
                    fluid(maxWidth: 340, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            pulsometr: file(relativePath: { eq: "pulsometr.png" }) {
                childImageSharp {
                    fluid(maxWidth: 340, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            heart3d: file(relativePath: { eq: "heart3d.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    return (
        <Wrapper id="innovative-diagnostic" maxWidth="1493px">
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 30 }}
                animate={controls}
                transition={{ duration: 0.3, delay: 0, ease: 'easeOut' }}
            >
                <SectionTitle>
                    {t('home_section_with_bullet_points_title')}
                </SectionTitle>
            </motion.div>
            <Row>
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -30 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.2, ease: 'easeOut' }}
                >
                    <LeftColumnDesktop>
                        <ImagesAndDecorations {...data} />
                    </LeftColumnDesktop>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: 30 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.2, ease: 'easeOut' }}
                >
                    <RightColumn>
                        <ul>
                            <li>
                                <h3>
                                    {t(
                                        'home_section_with_bullet_points_header_1'
                                    )}
                                </h3>
                                <p>
                                    {t(
                                        'home_section_with_bullet_points_message_1'
                                    )}
                                </p>
                            </li>
                            <li>
                                <h3>
                                    {t(
                                        'home_section_with_bullet_points_header_2'
                                    )}
                                </h3>
                                <p>
                                    {t(
                                        'home_section_with_bullet_points_message_2'
                                    )}
                                </p>
                            </li>
                            <HideDesktop>
                                <ImagesAndDecorations {...data} />
                            </HideDesktop>
                            <li>
                                <h3>
                                    {t(
                                        'home_section_with_bullet_points_header_3'
                                    )}
                                </h3>
                                <p>
                                    {t(
                                        'home_section_with_bullet_points_message_3'
                                    )}
                                </p>
                            </li>
                            <li>
                                <h3>
                                    {t(
                                        'home_section_with_bullet_points_header_4'
                                    )}
                                </h3>
                                <p>
                                    {t(
                                        'home_section_with_bullet_points_message_4'
                                    )}
                                </p>
                            </li>
                        </ul>
                    </RightColumn>
                </motion.div>
            </Row>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    max-width: 1493px;
    margin: 0 auto;
    padding: 160px 0 20px 0;
    h2 {
        padding: 0 24px;
        text-align: center;
        max-width: 800px;
        margin: 0 auto 120px auto;
        @media (max-width: 1330px) {
            margin-bottom: 80px;
            font-size: 36px;
            line-height: 45px;
        }
    }
    @media (max-width: 900px) {
        padding: 80px 0 40px 0;
    }
`

const GreenDecorator = styled(DiagnosticGreenDecorator)`
    position: absolute;
    top: -95px;
    left: 80px;
    z-index: 2;
    @media (max-width: 1330px) {
        height: 120px;
        left: calc(50% - 240px);
        top: -30px;
    }
`

const Heart3d = styled(Img)`
    height: 144px;
    width: 172px;
    transform: translate(-5px, 10px);
    @media (max-width: 900px) {
        height: 82px;
        width: 100px;
        transform: translate(-2.5px, 5px);
    }
`

const PulsometrImage = styled(Img)`
    position: absolute;
    left: 200px;
    top: 10%;
    width: 400px;
    height: 400px;
    @media (max-width: 1330px) {
        left: 16px;
        top: 30px;
        width: 243px;
        height: 243px;
    }
`
const WomanImageBox = styled.div`
    position: absolute;
`

const WomanImage = styled(Img)`
    position: absolute;
    left: 70px;
    top: -100px;
    width: 340px;
    height: 340px;
    @media (max-width: 1330px) {
        width: 199px;
        height: 199px;
        left: -30px;
        top: -25px;
    }
`

const PurpleCircle = styled.div`
    z-index: -1;
    position: absolute;
    top: -10px;
    left: -10px;
    background: #f3efff;
    height: 180px;
    width: 180px;
    border-radius: 50%;
    @media (max-width: 1330px) {
        top: 40px;
        left: -64px;
        height: 106px;
        width: 106px;
    }
`

const RedSquare = styled.div`
    display: flex;
    padding-top: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    z-index: -1;
    position: absolute;
    bottom: -20%;
    left: 300px;
    background: #ffe4ea;
    height: 216px;
    width: 216px;
    border-radius: 7px;
    @media (max-width: 1330px) {
        bottom: -40%;
        left: 130px;
        height: 126px;
        width: 126px;
    }
`

const Row = styled.div`
    margin: 0 24px;
    display: Grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (max-width: 1330px) {
        grid-template-columns: 1fr;
    }
`

const RightColumn = styled.div`
    width: 100%;
    padding-left: 5%;
    ul {
        li {
            position: relative;
            margin-bottom: 50px;
            list-style-type: none;
            h3 {
                margin-bottom: 30px;
            }
            p {
                color: #8f9fa9;
                line-height: 34px;
                font-size: 20px;
                max-width: 510px;
                @media (max-width: 1330px) {
                    max-width: 100%;
                }
                @media (max-width: 1330px) {
                    transform: translateX(-29px);
                    min-width: calc(100vw - 48px);
                }
            }
            &:before {
                position: absolute;
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #fa104d;
                left: -29px;
                top: 8px;
            }
            &:nth-of-type(2):before {
                background: #3d278a;
            }
            &:nth-of-type(3):before {
                background: #51e2c9;
            }
            &:nth-of-type(4):before {
                background: #04968b;
            }
        }
    }
    @media (max-width: 900px) {
        padding-left: 0;
    }
`

const LeftColumnDesktop = styled.div`
    width: 100%;
    display: flex;
    padding-top: 10vh;
    justify-content: center;
    @media (max-width: 1330px) {
        display: none;
    }
`

const HideDesktop = styled.div`
    @media (min-width: 1331px) {
        display: none;
    }
`

const ImagesAndDecorationsWrapper = styled.div`
    min-height: 600px;
    min-width: 600px;
    position: relative;
    @media (max-width: 1330px) {
        min-width: 200px;
        display: flex;
        justify-content: center;
    }
    @media (max-width: 900px) {
        margin-top: 100px;
    }
`
