import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
    border-radius: 12px;
    display: flex;
    align-items: center;
    background: ${props => props.color};
    max-width: ${props => (props.maxWidth ? '510px' : '100%')};
    margin-bottom: 10px;
    border: ${props => (props.border ? '1px solid #DFE5E9' : 'none')};
    > div {
        margin: 22px;
        border-radius: 50%;
        background: ${props => props.iconBg};
        min-width: 60px;
        height: 60px;
        display: flex;
        svg {
            max-width: 30px;
            max-height: 30px;
            margin: auto;
        }
    }
    > span {
        color: #2e2f45;
        line-height: 24px;
        font-size: 16px;
        font-weight: bold;
        padding: 40px 12px 40px 22px;
    }
    @media (max-width: 900px) {
        max-width: 100%;
        > span {
            padding: 12px 12px 12px 22px;
        }
    }
`

export default function IconSegment(props) {
    const { color, icon: Icon, text, border, iconBg, maxWidth } = props

    return (
        <Wrapper
            color={color}
            border={border ? 1 : 0}
            iconBg={iconBg}
            maxWidth={maxWidth}
        >
            <div>
                <Icon />
            </div>
            <span>{text}</span>
        </Wrapper>
    )
}

IconSegment.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.func,
    iconBg: PropTypes.string,
    maxWidth: PropTypes.bool,
}

IconSegment.defaultProps = {
    text: '',
    color: 'transparent',
    icon: () => {},
    iconBg: 'white',
    maxWidth: true,
}
