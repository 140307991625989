import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import IconSegment from '../../components/iconSegment'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import TickPurpleIcon from '../../images/TickPurpleIcon.svg'
import SmilePurpleIcon from '../../images/SmilePurpleIcon.svg'
import BookPurpleIcon from '../../images/BookPurpleIcon.svg'
import StatsPurpleIcon from '../../images/StatsPurpleIcon.svg'
import HeartPurpleIcon from '../../images/heartPurpleIcon.svg'

export function WhyUsSegments() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const controls = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])

    const Segments = [
        {
            text: t('for_doctor_why_us_segment1'),
            icon: HeartPurpleIcon,
            iconBg: '#F3EFFF',
        },
        {
            text: t('for_doctor_why_us_segment2'),
            icon: TickPurpleIcon,
            iconBg: '#F3EFFF',
        },
        {
            text: t('for_doctor_why_us_segment3'),
            icon: SmilePurpleIcon,
            iconBg: '#F3EFFF',
        },
        {
            text: t('for_doctor_why_us_segment4'),
            icon: BookPurpleIcon,
            iconBg: '#F3EFFF',
        },
        {
            text: t('for_doctor_why_us_segment5'),
            icon: StatsPurpleIcon,
            iconBg: '#F3EFFF',
        },
    ]
    return (
        <Wrapper>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 30 }}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                    ease: 'easeOut',
                }}
            >
                <h2>{t('for_doctor_why_us_header')}</h2>
            </motion.div>
            <SegmentsWrap>
                {Segments.map((segment, index) => {
                    const { iconBg, icon, text } = segment
                    return (
                        <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            animate={controls}
                            transition={{
                                duration: 0.3,
                                delay: 0.5 + index / 10,
                                ease: 'easeOut',
                            }}
                            key={index}
                        >
                            <IconSegment
                                text={text}
                                icon={icon}
                                iconBg={iconBg}
                                border={true}
                                maxWidth={false}
                            />
                        </motion.div>
                    )
                })}
            </SegmentsWrap>
        </Wrapper>
    )
}
const Wrapper = styled.section`
    padding: 20px 24px 160px 24px;
    width: 100%;
    h2 {
        margin-bottom: 70px;
    }
    @media (max-width: 900px) {
        height: auto;
        padding: 0px 24px 50px 24px;
        h2 {
            font-size: 36px;
            line-height: 45px;
        }
    }
`

const SegmentsWrap = styled.div`
    max-width: 1220px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.4fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: -2px;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto;
        grid-row-gap: 10px;
    }
`
