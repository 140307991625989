import React from 'react'
import styled from 'styled-components'
import doctorDecorator from './../../images/doctorDecorator.svg'
import doctorDots from './../../images/doctorDots.svg'
import { Button } from '../../components/button'
import { useIntl } from 'gatsby-plugin-intl'
import { Section, SectionTitle } from '../../components'
import { StaticImage } from 'gatsby-plugin-image'

export function Doctor() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    return (
        <Section minHeight="658px">
            <Row>
                <LeftColumn>
                    <div>
                        <SectionTitle>
                            {t('home_section_video_header')}
                        </SectionTitle>
                    </div>
                    <span>
                        {t('home_section_video_doctor_professional_name')}
                    </span>
                    <span>{t('home_section_video_hospital_name')}</span>
                    <Button>{t('home_section_video_button')}</Button>
                </LeftColumn>
                <RightColumn>
                    <DoctorImageBox>
                        <DoctorImgContainer>
                            <StaticImage src="../../images/Doctor.png" />
                        </DoctorImgContainer>
                        <DoctorDots />
                        <DoctorDecorator />
                    </DoctorImageBox>
                </RightColumn>
            </Row>
        </Section>
    )
}

const Row = styled.div`
    display: Grid;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 1fr;
    @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        width: 100%;
    }
`

const RightColumn = styled.div`
    @media (max-width: 1100px) {
        padding-top: 100px;
    }
`

const LeftColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px 180px;
    @media (max-width: 1100px) {
        padding: 0 24px;
    }
    > div {
        max-width: 540px;
        padding-top: 200px;
        @media (max-width: 1100px) {
            padding-top: 40px;
        }
        h2 {
            text-align: start;
            font-size: 40px;
            line-height: 54px;
            @media (max-width: 900px) {
                font-size: 28px;
                line-height: 40px;
                br {
                    display: none;
                }
            }
        }
    }
    span {
        width: 100%;
        font-size: 20px;
        &:nth-of-type(1) {
            line-height: 25px;
            font-weight: 700;
        }
        &:nth-of-type(2) {
            color: #8f9fa9;
            line-height: 23px;
            margin-bottom: 60px;
            @media (max-width: 1100px) {
                margin-bottom: 40px;
                font-size: 18px;
            }
        }
    }
`

const DoctorImageBox = styled.div`
    position: relative;
    width: 100%;
    transform: translate(0, 140px);
    @media (max-width: 1100px) {
        transform: translate(50px, 50px);
    }
`

const DoctorImgContainer = styled.div`
    width: 510px;
    height: 640px;
    margin: auto;
    @media (max-width: 1100px) {
        width: 300px;
        height: 300px;
    }
`

const DoctorDecorator = styled(doctorDecorator)`
    width: 247px;
    position: absolute;
    bottom: 130px;
    right: 380px;
    height: 255px;
    @media (max-width: 1100px) {
        width: 147px;
        bottom: 0;
        right: calc(50% + 50px);
    }
`

const DoctorDots = styled(doctorDots)`
    width: 224px;
    position: absolute;
    top: 22px;
    right: 330px;
    z-index: -1;
    @media (max-width: 1100px) {
        width: 128px;
        top: -67px;
        right: calc(50% + 42px);
    }
`
