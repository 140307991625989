import React, { useEffect } from 'react'
import styled from 'styled-components'
import Logo from '../../images/logo.svg'
import AppleIcon from '../../images/apple.svg'
import GooglePlayIcon from '../../images/google_play.svg'
import DownloadDecorator from '../../images/downloadDecorator.svg'
import { useIntl } from 'gatsby-plugin-intl'
import { colors } from '../../../src/utils/const'
import { SectionTitle } from '../../components'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

export function Download() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const { locale } = intl
    const [ref, inView] = useInView()
    const controls = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])
    return (
        <Wrapper id="download">
            <Row>
                <ColumnInfo>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, x: 0 }}
                        animate={controls}
                        transition={{
                            duration: 0,
                            delay: 0,
                            ease: 'easeOut',
                        }}
                    >
                        <Logo />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0,
                            ease: 'easeOut',
                        }}
                    >
                        <SectionTitle>
                            {t('common_download_title')}
                        </SectionTitle>
                        <InnerContainer>
                            <ButtonRow>
                                <a
                                    href="https://play.google.com/store/apps/details?id=app.kardiolog"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button>
                                        <GooglePlayIcon />
                                        <div>
                                            <span>
                                                {t('common_download_button')}
                                            </span>
                                            <span>Google Play</span>
                                        </div>
                                    </button>
                                </a>
                                <a
                                    href="https://apps.apple.com/app/kardiolog/id1575899930"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                <button>
                                    <AppleIcon />
                                    <div>
                                        <span>
                                            {t('common_download_button')}
                                        </span>
                                        <span>App Store</span>
                                    </div>
                                </button>
                                </a>
                            </ButtonRow>
                  
                        </InnerContainer>
                    </motion.div>
                </ColumnInfo>

                <ColumnPhone>
                    <PhoneBox>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={controls}
                            transition={{
                                duration: 0.3,
                                delay: 0,
                                ease: 'easeOut',
                            }}
                        >
                            <DoublePhonesContainer>
                                {locale === 'en' ? (
                                    <StaticImage
                                        src="../../images/doublePhones_en.png"
                                        alt="Double phones"
                                    />
                                ) : locale === 'de' ? (
                                    <StaticImage
                                        src="../../images/doublePhones_de.png"
                                        alt="Double phones"
                                    />
                                ) : (
                                    <StaticImage
                                        src="../../images/doublePhones_pl.png"
                                        alt="Double phones"
                                    />
                                )}
                            </DoublePhonesContainer>
                        </motion.div>
                        <DoubleShadowsContainer>
                            <StaticImage
                                src="../../images/doubleShadows.png"
                                alt="Double shadows"
                            />
                        </DoubleShadowsContainer>

                        <Decorator />
                    </PhoneBox>
                </ColumnPhone>
            </Row>
        </Wrapper>
    )
}

const InnerContainer = styled.div`
    @media (max-width: 900px) {
        text-align: left;
        .soon-available {
            margin-left: 165px;
        }
    }
    @media (max-width: 383px) {
        text-align: center;
        .soon-available {
            margin-left: 0;
        }
    }
`

const InfoText = styled.span`
    font-size: 18px;
    line-height: 32px;
    color: ${colors.purple};
    font-weight: 800;
    min-width: 160px;
    text-align: center;
    @media (min-width: 900px) {
        margin-left: 190px;
    }
`

const Wrapper = styled.section`
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 843px;
    background: linear-gradient(180deg, #ffffff 0%, #edf2f4 98.86%);
    h2 {
        margin-top: 32px;
        margin-bottom: 40px;
        font-size: 50px;
        line-height: 65px;
        text-align: start;
        max-width: 800px;
    }
    @media (max-width: 900px) {
        height: auto;
        h2 {
            font-size: 36.02px;
            line-height: 45px;
            text-align: center;
        }
    }
`

const Row = styled.div`
    max-width: 1338px;
    padding: 0 24px;
    margin: 0 auto;
    display: Grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`

const ColumnInfo = styled.div`
    padding-top: 260px;
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
        padding-top: 0px;
        justify-content: center;
        align-items: center;
    }
`

const ColumnPhone = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
        justify-content: center;
        align-items: center;
    }
`

const ButtonRow = styled.div`
    display: flex;
    justify-content: left;
    a {
        text-decoration: none;
    }
    button {
        transition: 0.2s ease-in-out;
        border: 0;
        height: 56px;
        width: 160px;
        color: white;
        font-weight: 700;
        background: rgba(61, 39, 138, 1);
        box-shadow: 0px 4px 10px rgba(61, 39, 138, 0.25);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 10px;
        margin-bottom: 30px;
        &:hover {
            background-color: rgba(61, 39, 138, 0.85);
        }
        &:disabled {
            background-color: grey;
        }
        div {
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            span:nth-of-type(1) {
                font-size: 8px;
                line-height: 10px;
                opacity: 0.6;
                text-transform: uppercase;
            }
            span:nth-of-type(2) {
                font-weight: 800;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    @media (max-width: 900px) {
        justify-content: center;
        button {
            width: 158px;
            margin: 5px;
        }
    }
    @media (max-width: 900px) {
        flex-wrap: wrap;
    }
    @media (max-width: 500px) {
        flex-wrap: wrap;
        align-items: center;
    }
`

const PhoneBox = styled.div`
    position: relative;
    @media (max-width: 900px) {
        max-width: 90vw;
    }
`

const DoublePhonesContainer = styled.div`
    margin-top: 137px;
    width: 410px;
    height: 590px;
    @media (max-width: 900px) {
        width: 244px;
        height: 351px;
        margin: 138px auto;
    }
    @media (max-width: 900px) {
        margin: 138px auto 40px;
    }
`
const DoubleShadowsContainer = styled.div`
    width: 442px;
    height: 69px;
    @media (max-width: 900px) {
        position: absolute;
        top: -140px;
        width: 277px;
        height: 43px;
    }
`

const Decorator = styled(DownloadDecorator)`
    position: absolute;
    left: 490px;
    top: 337px;
    @media (max-width: 900px) {
        transform: rotate(-90deg) scale(0.7);
        left: calc(50% - 30px);
        top: 0px;
    }
`
