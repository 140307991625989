import React from 'react'
import ReactMarkdown from 'react-markdown'
import privacyEn from '../../markdown-pages/en/privacy-policy-mobile.md'
import privacyPl from '../../markdown-pages/pl/privacy-policy-mobile.md'

export const PrivacyPolicyMobile = ({ locale }) => {
    switch (locale) {
        case 'en':
            return <ReactMarkdown>{privacyEn}</ReactMarkdown>
        default:
            return <ReactMarkdown>{privacyPl}</ReactMarkdown>
    }
}
