import React, { useEffect } from 'react'
import styled from 'styled-components'
import Pencil from '../../images/pencil.svg'
import Bars from '../../images/bars.svg'
import Alert from '../../images/alert.svg'
import Users from '../../images/users.svg'
import IconSegment from '../../components/iconSegment'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'

export function HomeSectionGreySegments() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }

    const [ref, inView] = useInView()
    const controls = useAnimation()
    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                x: 0,
            })
        }
    }, [controls, inView])

    const data = useStaticQuery(graphql`
        query {
            PhonesGroupEn: file(relativePath: { eq: "phonesGroup_en.png" }) {
                childImageSharp {
                    fluid(maxHeight: 780, maxWidth: 609, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }

            PhonesGroupDe: file(relativePath: { eq: "phonesGroup_pl.png" }) {
                childImageSharp {
                    fluid(
                        maxHeight: 780
                        maxWidth: 609
                        quality: 100
                        grayscale: true
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }

            PhonesGroupPl: file(relativePath: { eq: "phonesGroup_pl.png" }) {
                childImageSharp {
                    fluid(maxHeight: 780, maxWidth: 609, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    const { locale } = intl

    const dataPhonesGroup =
        locale === 'en'
            ? data.PhonesGroupEn
            : locale === 'de'
            ? data.PhonesGroupDe
            : data.PhonesGroupPl

    const Segments = [
        {
            text: t('home_section_grey_segments_segment_1'),
            icon: Pencil,
        },
        {
            text: t('home_section_grey_segments_segment_2'),
            icon: Bars,
        },
        {
            text: t('home_section_grey_segments_segment_3'),
            icon: Alert,
        },
        {
            text: t('home_section_grey_segments_segment_4'),
            icon: Users,
        },
    ]
    return (
        <Wrapper background="#eef2f5">
            <Row>
                <ColumnPhonesMobile locale={locale}>
                    <StaticImage
                        src="../../images/phonesGroup_en.png"
                        alt="phones group"
                        className="phones-group-en"
                    />
                    <StaticImage
                        src="../../images/phonesGroup_pl.png"
                        alt="phones group"
                        className="phones-group-pl"
                    />
                </ColumnPhonesMobile>
                <ColumnSegments>
                    <FlexWrapper>
                        {Segments.map((segment, index) => {
                            const { text, icon } = segment
                            return (
                                <motion.div
                                    ref={index === 0 ? ref : null}
                                    initial={{ opacity: 0, x: -30 }}
                                    animate={controls}
                                    transition={{
                                        duration: 0.3,
                                        delay: 0.3 + index / 10,
                                        ease: 'easeOut',
                                    }}
                                    key={index}
                                >
                                    <IconSegment
                                        border
                                        text={text}
                                        icon={icon}
                                    />
                                </motion.div>
                            )
                        })}
                    </FlexWrapper>
                </ColumnSegments>
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={controls}
                    transition={{
                        duration: 0.3,
                        delay: 0.5,
                        ease: 'easeOut',
                    }}
                >
                    <ColumnPhones>
                        <PhoneGroup
                            fadeIn={false}
                            fluid={dataPhonesGroup.childImageSharp.fluid}
                        />
                    </ColumnPhones>
                </motion.div>
            </Row>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    padding: 0 24px 100px 24px;
    margin: 0 auto;
    width: 100%;
    background: #eef2f5;
    height: 780px;
    @media (max-width: 900px) {
        height: auto;
        padding: 0 24px 80px 24px;
    }
`

const Row = styled.div`
    display: Grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`

const ColumnSegments = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ColumnPhones = styled.div`
    position: relative;
    @media (max-width: 900px) {
        display: none;
    }
`

const ColumnPhonesMobile = styled.div`
    position: relative;
    display: flex;
    @media (min-width: 901px) {
        display: none;
    }
    .phones-group-en {
        display: ${({ locale }) => (locale === 'en' ? 'block' : 'none')};
    }
    .phones-group-pl {
        display: ${({ locale }) => (locale === 'pl' ? 'block' : 'none')};
    }
`

const FlexWrapper = styled.div`
    height: 100%;
    width: 100%;
    max-width: 510px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 900px) {
        max-width: 100%;
    }
`

const PhoneGroup = styled(Img)`
    height: 780px;
    width: 608px;
    @media (max-width: 900px) {
        height: auto;
        width: 80%;
        margin: 0 auto 30px auto;
    }
`
