import React from 'react'
import styled from 'styled-components'
import { colors } from '../../utils/const'
import IntroShape from '../../images/IntroShape.svg'

import Heart from '../../images/heart.svg'
import Pulse from '../../images/pulse.svg'
import Scale from '../../images/scale.svg'
import { Button } from '../../components/button'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { motion } from 'framer-motion'

const PhoneDecorationContent = props => {
    const { locale } = props
    const data =
        locale === 'en'
            ? props.PhoneEn
            : locale === 'de'
            ? props.PhoneDe
            : props.PhonePl
    return (
        <div>
            <PhoneImageBox>
                <motion.div
                    initial={{ opacity: 0, x: 30 }}
                    animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                            duration: 0.3,
                            delay: 0.3,
                            ease: 'easeOut',
                        },
                    }}
                >
                    <PhoneImg
                        loading="eager"
                        fadeIn={false}
                        fluid={data.childImageSharp.fluid}
                    />
                </motion.div>
            </PhoneImageBox>
            <AbsoluteIntroShape />
        </div>
    )
}
export function Intro() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    const { locale } = intl
    const data = useStaticQuery(graphql`
        query {
            PhonePl: file(relativePath: { eq: "phone_shadow_pl.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            PhoneDe: file(relativePath: { eq: "phone_shadow_de.png" }) {
                childImageSharp {
                    fluid(quality: 100, grayscale: true) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
            PhoneEn: file(relativePath: { eq: "phone_shadow_en.png" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)

    return (
        <Wrapper>
            <Content>
                <motion.div
                    initial={{ opacity: 0, y: 50, zIndex: 99 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            duration: 0.5,
                            delay: 0,
                            ease: 'easeOut',
                        },
                    }}
                >
                    <h1>{t('home_intro_title')}</h1>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: 50, zIndex: 99 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            duration: 0.5,
                            delay: 0.1,
                            ease: 'easeOut',
                        },
                    }}
                >
                    <Button onClick={() => scrollTo('#innovative-diagnostic')}>
                        {t('home_intro_button_scroll')}
                    </Button>
                </motion.div>
                <PhoneDecorationMobile>
                    <PhoneDecorationContent {...data} locale={locale} />
                </PhoneDecorationMobile>
                <motion.div
                    initial={{ opacity: 0, y: 50, zIndex: 99 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            duration: 0.5,
                            delay: 0.3,
                            ease: 'easeOut',
                        },
                    }}
                >
                    <FeaturesTitle>
                        {t('home_intro_icons_title')}{' '}
                    </FeaturesTitle>
                    <FeatureBox>
                        <div>
                            <Heart />
                        </div>
                        <div>
                            <Pulse />
                        </div>
                        <div>
                            <Scale />
                        </div>
                    </FeatureBox>
                </motion.div>
            </Content>

            <PhoneDecorationDesktop>
                <PhoneDecorationContent {...data} locale={locale} />
            </PhoneDecorationDesktop>
        </Wrapper>
    )
}

const Wrapper = styled.section`
    position: relative;
    overflow: visible;
    width: 100%;
    background: linear-gradient(180deg, #fbfbfd 53.83%, #eef2f5 133.35%);
    display: flex;
    @media only screen and (min-device-width: 480px) and (max-device-width: 823px) and (orientation: landscape) {
        min-height: 100vw;
    }
    @media (min-width: 900px) {
        min-height: 100vh;
    }
`

const Content = styled.div`
    width: 100%;
    overflow: hidden;
    max-width: 1445px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 1;
    h1 {
        position: relative;
        z-index: 5;
        font-size: 60px;
        margin-top: calc((100vh - 370px) / 2);
        max-width: 55%;
        margin-bottom: 5vh;
    }
    @media (min-width: 1300px) {
        h1 {
            max-width: 800px;
        }
    }
    @media (max-width: 900px) {
        br {
            display: none;
        }
        width: 100vw;
        margin-top: 160px;
        display: flex;
        flex-direction: column;
        h1 {
            margin-top: 2vh;
            font-size: 36px;
            max-width: 800px;
        }
    }
    @media (max-width: 350px) {
        margin-top: 60px;
        h1 {
            margin-top: 10vh;
            font-size: 30px;
        }
    }

    button {
        z-index: 9;
        position: relative;
    }
`

const AbsoluteIntroShape = styled(IntroShape)`
    position: absolute;
    top: -600px;
    right: -12%;
    width: 925px;
    height: 1147px;
    @media (max-width: 900px) {
        top: 0;
        right: -25px;
        width: auto;
        height: 80%;
    }
    // @media (min-width: 1981px) {
    //     top: -50%;
    //     right: -12%;
    //     height: 120vmin;
    //     width: auto;
    // }
    // @media only screen and (min-device-width: 480px) and (max-device-width: 823px) and (orientation: landscape) {
    //     right: 25%;
    // }
`

const PhoneImageBox = styled.div`
    position: absolute;
    bottom: 10vmin;
    right: 8vw;
    z-index: 1;
    @media (max-width: 900px) {
        position: relative;
        right: 0;
        top: 0;
        margin: 10%;
    }
    @media (min-width: 1981px) {
        bottom: 10vh;
    }
    // @media (max-width: 400px) {
    //     top: 50vmin;
    // }
    @media only screen and (min-device-width: 480px) and (max-device-width: 823px) and (orientation: landscape) {
        right: 90px;
        top: 100px;
    }
    @media only screen and (min-device-height: 760px) and (orientation: landscape) {
        right: 0px;
        top: 100px;
    }
`

const FeaturesTitle = styled.div`
    margin-bottom: 24px;
    color: ${colors.mediumGrey};
    margin-top: 166px;
    @media (max-width: 900px) {
        margin-top: auto;
        text-align: center;
        margin-bottom: 5%;
    }
`

const FeatureBox = styled.div`
    display: flex;
    div {
        margin-right: 10px;
        height: 104px;
        width: 104px;
        border-radius: 10px;
        border: 2px solid #dfe5e9;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 900px) {
        margin-bottom: 30%;
        justify-content: center;
        div {
            height: 84px;
            width: 84px;
        }
        > div:nth-of-type(3) {
            margin: 0;
        }
    }
    //Ipad fix
    @media (min-width: 700px) and (max-width: 770px) {
        margin-bottom: 25%;
    }
    @media (min-width: 1981px) {
        div {
            height: 144px;
            width: 144px;
            svg {
                transform: scale(1.1);
            }
        }
    }
`

const PhoneImg = styled(Img)`
    width: 460px;
    height: 707px;
    z-index: 2;
    display: block;
    margin: 0 auto;
    @media (max-width: 700px) {
        width: 325px;
        height: 500px;
    }
    @media (max-width: 400px) {
        width: 162px;
        height: 250px;
    }
    @media (orientation: landscape) {
        max-height: 100vmin;
        max-width: calc(100vmin / (707 / 460));
    }
`

const PhoneDecorationDesktop = styled.div`
    position: absolute;
    top: 10%;
    right: 10%;
    height: 100vh;
    width: 100vw;
    @media (max-width: 900px) {
        display: none;
    }
`

const PhoneDecorationMobile = styled.div`
    position: relative;
    // top: -16%;
    // right: -20%;
    width: 100%;

    @media (min-width: 901px) {
        display: none;
    }
    // @media (min-width: 8000px) {
    //     top: -20%;
    //     right: -20%;
    // }

    // @media (max-width: 400px) {
    //     top: -13%;
    // }

    // @media (max-width: 350px) {
    //     top: -22%;
    //     right: -32%;
    // }
    // @media only screen and (min-device-width: 480px) and (max-device-width: 900px) and (orientation: landscape) {
    //     top: 0;
    //     right: -30%;
    // }
`
